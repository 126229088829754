import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demo1 from '../assets/images/screen-1.png';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-3">
                LiftUp
              </h1>
              <h3 className="mb-5">データドリブンなワークアウトを<br />可能にするアプリ</h3>
              <Scroll type="id" element="download">
                <a href="#download" className="btn btn-outline btn-xl">
                  Start Now for Free!
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">

              <div className='phone'>
                <div className="inner">
                  <img src={demo1} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h3 className="section-heading">
              Available on Web!!
            </h3>
            <div className="badges">

              <a href="https://app.liftup.fitness" className="btn btn-outline btn-xl">
                Let's Get Started!
              </a>
              {/*
              <a className="badge-link" href="/#">
                <img src={gplay} alt="" />
              </a>
              <a className="badge-link" href="/#">
                <img src={astore} alt="" />
              </a>
              */}

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>LiftUpの機能</h2>
          <p className="text-muted">
            Check out what you can do with this app!
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className='phone black'>
                <div className="inner">
                  <img src={demo1} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">

                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="fa fa-camera text-primary"></i>
                    <h3>Plan</h3>
                    <p className="text-muted">
                      上半身、下半身など、1回のワークアウトで行うメニューをあらかじめ保存しておくことができます。
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="fa fa-dumbbell text-primary"></i>
                    <h3>Record</h3>
                    <p className="text-muted">
                      行ったワークアウトを記録できます。Planを使えばワークアウトの度にトレーニング種目を入力する必要はありません。
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="fa fa-chart-line text-primary"></i>
                    <h3>Analyze</h3>
                    <p className="text-muted">
                      記録したワークアウトは見返すことができます。種目ごとの推定MaxRM、最大重量、重量の変化履歴などを確認できます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>
            Start Workout.
          </h2>
          <Scroll type="id" element="contact">
            <a href="https://app.liftup.fitness" className="btn btn-outline btn-xl">
              Let's Get Started!
            </a>
          </Scroll>
        </div>
      </div>
      <div className="overlay"></div>
    </section>

    {/*

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          We
          <i className="fas fa-heart"></i>
          new friends!
        </h2>
        <Social />
      </div>
    </section>
*/}


    <Footer />
  </Layout>
);

export default IndexPage;
